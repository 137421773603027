import { CategoryPage } from '@genially/public-web-components';
import type { FC } from 'react';
import WithLayout from '../WithLayout';
import parseBreadcrumbs from 'core/infrastructure/parseBreadcrumbs';
import { translate } from 'core/infrastructure/translator';
interface DataProps {
  content: any;
}

const TemplateCategory: FC<DataProps> = ({ content }) => {
  const translateFromContent = (key: string) => translate(content.langcode.value, key);
  let templates = content.categories[0].category.mainRelatedContents
    ? content.categories[0].category.mainRelatedContents.entities
    : null;

  templates = templates.filter(
    (template: any) => template.bundle === 'template' && template.excluded !== true
  );

  const breadcrumbs = parseBreadcrumbs(content, 'template_category');

  const extraCategories: any = [];
  if (content.extra && content.extra[0]) {
    content.extra.map((cat: any) => {
      const category = {
        name: cat.category.name,
        extra: true,
        mainRelatedContents: cat.category.mainRelatedContents,
        tid: cat.category.tid,
      };
      extraCategories.push(category);
    });
  }
  return (
    <main role="main" data-node-id={content.id} about={content.url?.path}>
      <CategoryPage
        breadcrumbItems={breadcrumbs}
        faqs={content.faqs ? content.faqs : null}
        banner={content.banner ? content.banner : null}
        description={content.description ? content.description.value : null}
        breadcrumb={translateFromContent('templates.breadcrumbback')}
        templates={templates}
        masonry={content.masonry}
        teaser={content.teaser}
        header={content.header}
        subcategories={content.custom ? content.custom.data.content.entities : null}
        extraCategories={extraCategories}
        tid={content.categories[0].category.tid}
        lang={content.langcode.value}
        parentId={
          content.categories[0].category.parent
            ? content.categories[0].category.parent[0].entity.tid
            : null
        }
      />
    </main>
  );
};

export default WithLayout(TemplateCategory);
